.datingCards__container {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}

.card {
    position: relative;
    background-color: white;
    width: 600px;
    padding: 20px;
    max-width: 85vw;
    height: 50vh;
    box-shadow: 0px 18px 53px 0px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}

.swipe {
    position: absolute;
}

.cardContent {
    width: 100%;
    height: 100%;
}

.card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: white;
}