.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    border-bottom: 1px solid #f9f9f9;
}

.header__logo {
    object-fit: contain;
    height: 40px;
}

.header__icon {
    padding: 20px;
}